.b-video {
	position: relative;
	&::before {
		content: '';
		display: block;
		aspect-ratio: 16 / 9;
	}
	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		inset: 0;
	}
}
