@use 'config';

.scrollbar {
	overflow-y: auto;
	overscroll-behavior: contain;
	&::-webkit-scrollbar {
		width: 0.3125rem;
	}
	&::-webkit-scrollbar-track {
		background: $body-bg;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 0.5rem;
		background: #717785;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: $primary;
	}
	&.dropdown-menu__scroll {
		position: absolute !important;
		inset: 0;
		padding-right: 10px !important;
		> ul {
			padding: 0;
		}
	}
	&--horizontal {
		overflow-y: visible;
		overflow-x: auto;
		&::-webkit-scrollbar {
			height: 0.3125rem;
		}
		&::-webkit-scrollbar-track {
			background: #ebedf7;
		}
	}
	@media (config.$lg-up) {
		&.dropdown-menu {
			max-height: 50dvh;
			margin-right: 0.5rem;
		}
		&.dropdown-menu__scroll {
			position: static !important;
			max-height: 50dvh;
			padding-right: 2px !important;
		}
	}
}

[data-bs-theme=dark] {
	.scrollbar {
		&::-webkit-scrollbar-track {
			background: #181c26;
		}
		&::-webkit-scrollbar-thumb {
			background: #8B919F;
		}
	}
}
