@use 'config';

.toggle-width {
	position: absolute;
	top: 50%;
	left: -0.5rem;
	display: none;
	z-index: 1020;
	&__link {
		padding: 0.75rem 0.3rem 0.85rem;
		border: 1px solid $border-color;
		border-radius: 1.5rem;
		background: $white;
		color: $body-color;
		transition: border-color $t;
		.icon-svg {
			margin-inline: -0.4rem;
		}
	}
	// STATES
	.hoverevents &__link:hover {
		border-color: #717785;
	}

	@media (config.$lg-up) {
		display: block;
	}
}

[data-bs-theme=dark] {
	.toggle-width {
		&__link {
			border-color: #323742;
			background: #0C0F17;
			color: #C0C6D6;
		}
	}
	&.hoverevents .toggle-width__link:hover {
		background: #223148;
		border-color: #8B919F;
		color: #E0E2EC;
	}
}
