.b-tags {
	&__title {
		margin-bottom: 0.75rem;
		font-weight: $font-weight-bold;
		font-size: 0.75rem;
	}
	&__list {
		@extend %reset-ul;
		display: flex;
		gap: 0.5rem;
		flex-wrap: wrap;
		font-size: 0.875rem;
	}
	&__item {
		@extend %reset-ul-li;
	}
	&__link {
		display: flex;
		gap: 0.25rem;
		align-items: center;
		min-height: 1.625rem;
		padding: 0.1rem 0.5rem;
		border-radius: 1.5rem;
		background: #ecf1ff;
		color: $dark-color;
		transition: color $t;
		.icon-svg--360 {
			color: #BA1A1A;
		}
	}
	// STATES
	.hoverevents &__link:hover {
		color: $primary;
	}
}

[data-bs-theme=dark] {
	.b-tags {
		&__link {
			background: #223148;
			color: #E0E2EC;
			.icon-svg--360 {
				color: #FFB4AB;
			}
		}
	}
}
