[data-bs-theme=dark] {
	.form {
		&-label {
			color: #E0E2EC;
		}
		&-control {
			background: #0C0F17;
			border-color: #323742;
			&::placeholder {
				color: #323742;
			}
		}
		&-check-input {
			background-color: #0C0F17;
			&:checked {
				background-color: #0074db;
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%230C0F17'/%3e%3c/svg%3e");
			}
		}
	}
}