@use 'config';

html {
	height: 100dvh;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	overscroll-behavior: none;
	> * {
		transition: all $t;
	}
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
body {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 20rem;
	height: 100dvh;
}
:first-child:not(.col) {
	margin-top: 0;
}

.layout {
	display: grid;
	grid-column: 1/4;
	transition: grid-template-columns $t;
	&__side {
		padding: 2rem 0.75rem 0.75rem;
		z-index: 1005;
	}
	&__side-inner {
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	&__scroll {
		height: 100%;
		padding-right: $spacer * 0.75;
		display: flex;
		flex-direction: column;
	}
	&__top {
		display: flex;
		gap: 0.75rem;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.75rem;
	}
	&__tools {
		display: flex;
		gap: 0.75rem;
		align-items: center;
	}
	&__fix {
		position: sticky;
		bottom: 0;
		display: none;
		text-align: center;
	}
	&__map {
		position: relative;
		display: flex;
		flex-direction: column;
	}
	@media (config.$lg-down) {
		&__side {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			display: flex;
			flex-direction: column;
			height: 5rem;
			min-height: 5rem !important;
			max-height: 100dvh !important;
			border: 1px solid #dadfeb;
			border-width: 1px 1px 0;
			border-radius: 1.5rem 1.5rem 0 0;
			background: $white;
			box-shadow: 0 -2px 3px 0 rgba($black, 0.02), 0 -6px 6px 0 rgba($black, 0.02), 0 -14px 8px 0 rgba($black, 0.01);
		}
		&__panel {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			z-index: 205;
			display: block;
			height: 2rem;
			&::before {
				content: '';
				position: absolute;
				top: 0.75rem;
				left: 50%;
				width: 8.375rem;
				height: 0.3125rem;
				margin-left: -4.1875rem;
				border-radius: 1rem;
				background: #dadfeb;
			}
		}
		&__top {
			margin-bottom: $spacer * 1;
			.dropdown {
				position: static;
			}
			.dropdown-menu {
				position: absolute !important;
				top: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				left: 0 !important;
				inset: 0 -2px 48px !important;
				border: none !important;
				transform: translate3d(0, 48px, 0) !important;
				box-shadow: none !important;
				border-radius: 0 !important;
			}
		}
		// STATE
		&__side.is-full {
			border-radius: 0;
			border-color: transparent;
		}
	}
	@media (config.$lg-up) {
		grid-template-columns: 33.3333% 66.6666%;
		grid-column: 2/4;
		&__side {
			padding: $spacer * 0.5 $spacer * 0.75 0.5rem 0;
		}
		&__side-inner {
			display: contents;

		}
		&__scroll {
			height: calc(100dvh - 11.625rem);
		}
		&__fix {
			display: block;
		}
		// MODIF
		&--3-1 {
			grid-template-columns: 66.6666% 33.3333%;
			[data-proportion='layout--3-1'] {
				background: $primary;
				color: $white;
			}
			.toggle-width__link {
				.icon-svg--chevron {
					opacity: 0.1;
				}
			}
		}
		&--1-3 {
			grid-template-columns: 33.3333% 66.6666%;
			[data-proportion='layout--1-3'] {
				background: $primary;
				color: $white;
			}
			.toggle-width__link {
				.icon-svg--chevron-left {
					opacity: 0.1;
				}
			}
		}
		&--1-1 {
			grid-template-columns: 50% 50%;
			[data-proportion='layout--1-1'] {
				background: $primary;
				color: $white;
			}
		}
	}
	@media (config.$xl-up) {
		&__scroll {
			height: calc(100dvh - 6.625rem);
		}
	}
}
[data-bs-theme=dark] {
	.layout {
		@media (config.$lg-down) {
			&__side {
				background: #0C0F17;
				border-color: #323742;
			}
			&__panel {
				&::before {
					background: #323742;
				}
			}
		}
	}

	.leaflet-layer,
	.leaflet-control-zoom-in,
	.leaflet-control-zoom-out,
	.leaflet-control-attribution,
	.leaflet-control-layers{
		filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
	}

}

// TYPOGRAPHY
a {
	text-decoration: none;
}
sup {
	color: $danger;
	font-weight: $font-weight-bold;
	cursor: pointer;
}
@include color-mode(dark) {
	sup {
		color: $danger-light-dark;
	}
}
.color-danger {
	color: $danger;
}
.radius {
    border-radius: 50%;
}
