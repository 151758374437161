@use 'config';

.b-map {
	position: relative;
	flex: 1 1 auto;
	&__map {
		position: absolute;
		width: 100%;
		height: 100%;
		inset: 0;
		iframe {
			position: absolute;
			width: 100%;
			height: 100%;
			inset: 0;
		}

	}
	&--border {
		display: none;
	}
	// STATES
	&--border.is-active {
		display: block;
	}
	@media (config.$lg-up) {
		// MODIF
		&--border {
			display: none;
			border: 0.5rem solid #ebedf7;
			border-width: 0.5rem 0 0 0.5rem;
		}
	}

	.pulse {
		animation: pulsate 1s ease-out infinite;
		opacity: 0.0;
	}

	@keyframes pulsate {
		0% {
			opacity: 0.0;
		}
		50% {
			opacity: 1.0;
		}
		100% {
			opacity: 0.0;
		}
	}

	.leaflet-top, .leaflet-bottom {
		z-index: 400;
	}

	.leaflet-popup{
		display:none !important;
	}

	.leaflet-tooltip-bottom {
		margin-top: 10px !important;
	}

	.leaflet-control-attribution{
		display: none !important;
	}

	.tooltip-place {
		background: transparent;
		border: none;
		box-shadow: none;
		font-weight: bold;
		color: black;
		text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
	}

	.tooltip-place::before {
		border: none;
	}

	.leaflet-popup-content-wrapper {
		text-align: center;
	}

	.countryLabel {
		/*    position: relative;*/
		opacity: 90%;
		color: black;
		text-transform: uppercase;
		width: 20em !important;
		/*    left: -20%;*/
		font-size: 14pt;
		font-weight: 400;
		display: block;
		text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
		letter-spacing: 0.1em;
	}

	/* Use the ::before pseudo-element to add background text */
	.layout__map::before {
		content: "Select some tab to see content."; /* The text to display in the background */
		position: absolute; /* Position the text absolutely */
		top: 50%; /* Center the text vertically */
		left: 50%; /* Center the text horizontally */
		transform: translate(-50%, -50%); /* Translate to truly center the text */
		font-size: 24px; /* Set the font size of the background text */
		color: rgba(0, 0, 0, 0.1); /* Set the text color and opacity */
		white-space: nowrap; /* Prevent text wrapping */
		z-index: 0; /* Ensure the background text is behind other content */
	}

	/* Style the content inside the div */
	.layout__map .background-text {
		position: relative; /* Ensure content is above the pseudo-element */
		z-index: 1; /* Ensure the content is above the background text */
		padding: 20px;
		font-size: 16px;
	}
}
[data-bs-theme=dark] {
	.b-map {
		@media (config.$lg-up) {
			&--border {
				border-color: #2D333C;
			}
		}
	}
}
