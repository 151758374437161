@use 'base/variables';
@use 'config';

.header {
	position: relative;
	padding: $spacer * 0.25 0 calc($spacer * 0.25 - 1px);
	border-bottom: 1px solid variables.$border-color;
	&__wrap {
		display: flex;
		gap: 0.75rem;
		justify-content: space-between;
		align-items: center;
	}
	&__logo {
		display: block;
		margin: 0;
		padding: 0;
		font-size: 1.375rem;
		line-height: 1;
		img {
			width: 100%;
			height: auto;
		}
	}
	&__main {
		z-index: 1011;
	}
	&__side {
		flex: 1 1 auto;
		margin: 0;
		z-index: 1020;
	}
	@media (config.$lg-down) {
		z-index: 10000;
		background: $white;
		&__logo {
			img {
				width: 7.9375rem;
				height: auto;
			}
		}
		&__search {
			position: absolute;
			top: 100%;
			right: 0;
			left: 0;
			z-index: 3;
			padding: 0.75rem 0.25rem;
			border-top: 1px solid #dadfeb;
			background: $white;
			visibility: hidden;
			opacity: 0;
			transition: opacity $t, visibility $t;
			pointer-events: none;
			box-shadow: 0 4px 9px 0 rgba(#000000, 0.1), 0 16px 16px 0 rgba(#000000, 0.09), 0 36px 22px 0 rgba(#000000, 0.05),
				0 16px 16px 0 rgba(#000000, 0.09);
		}
		&__main {
			position: absolute;
			top: 100%;
			right: 0;
			left: 0;
			z-index: 3;
			padding: 4.5625rem 0 0;
			border-top: 1px solid #dadfeb;
			background: $white;
			visibility: hidden;
			opacity: 0;
			transition: opacity $t, visibility $t;
			pointer-events: none;
			box-shadow: 0 4px 9px 0 rgba(#000000, 0.1), 0 16px 16px 0 rgba(#000000, 0.09), 0 36px 22px 0 rgba(#000000, 0.05),
				0 16px 16px 0 rgba(#000000, 0.09);
		}
		&__side {
			position: absolute;
			top: 100%;
			right: 0;
			left: 0;
			z-index: 4;
			padding: 0.75rem;
			border-bottom: 1px solid #dadfeb;
			visibility: hidden;
			opacity: 0;
			transition: opacity $t, visibility $t;
			pointer-events: none;
		}
		&__mobile {
			display: flex;
			gap: 0.75rem;
			justify-content: center;
			align-items: center;
			margin: 0;
		}
		&__menu {
			display: block;
			padding: 0.875rem;
			color: #505f79;
		}
		&__mobile-search {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0.875rem;
			border: 1px solid #dadfeb;
			border-radius: 50%;
			color: #505f79;
			transition: background $t, border-color $t;
		}
		// STATES
		&.is-search-open &__search,
		&.is-menu-open &__main,
		&.is-menu-open &__side {
			visibility: visible;
			opacity: 1;
			pointer-events: all;
		}
		&__mobile-search.is-search-open {
			border-color: #505f79;
			background: #ecf1ff;
		}
	}
	@media (config.$md-down) {
		&__main {
			padding-top: 7.75rem;
		}
	}
	@media (config.$lg-up) {
		&__mobile {
			display: none;
		}
		&__main {
			display: flex;
			gap: $spacer * 0.75;
			align-items: center;
		}
	}
	@media (config.$xl-up) {
		&__logo {
			margin-right: 2.875rem;
		}
	}
}

[data-bs-theme=dark] {
	.header {
		border-bottom: 1px solid $border-color-dark;
		&__menu {
			color: #C0C6D6;
		}
		&__mobile-search {
			border-color: $border-color-dark;
			color: #C0C6D6;
		}
		&__search {
			background: #0C0F17;
		}
		@media (config.$lg-down) {
			background: #181c26;
			&__side,
			&__main {
				border-bottom: 1px solid $border-color-dark;
			}
			&__main {
				background: #0C0F17;
			}
			&__search {
				border-top: 1px solid $border-color-dark;
			}
		}
	}
}
