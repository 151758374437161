@use 'config';

.m-main {
	font-size: 0.875rem;
	line-height: 1rem;
	&__list {
		@extend %reset-ul;
	}
	&__item {
		@extend %reset-ul-li;
	}
	&__link {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		padding: 0.8125rem 0.4375rem;
		color: $color-grey;
		text-decoration: none;
		transition: border-color $t, background $t, color $t;
	}
	&__logout {
		color: $color-grey;
	}
	// STATES
	.hoverevents &__link:hover {
		border-color: $primary;
	}
	// MODIF
	&__item--lang &__link {
		img {
			border-radius: 50%;
		}
	}
	&--lg &__link {
		padding: 0.75rem;
		border: 1px solid #dadfeb;
	}
	@media (config.$lg-down) {
		padding: 0.75rem 0.75rem 0;
		&__item {
			.dropdown-menu {
				position: static !important;
				border: none !important;
				background: none !important;
				transform: translate3d(0, 0, 0) !important;
				box-shadow: none !important;
			}
		}
		&__item:last-child {
			border-top: 1px solid #dadfeb;
			margin-inline: -0.75rem;
		}
		&__item:last-child &__link {
			padding: 1.5rem 1.25rem;
		}
		&__avatar {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1.5rem 1.25rem;
		}
		&__link {
			border-radius: 0.5rem;
		}
		// STATES
		&__link.show {
			background: #f5f5ff;
			color: $dark-color;
		}
		// MODIF
		&--lg {
			padding: 0;
		}
		&--lg &__list {
			display: flex;
			gap: $spacer * 0.75;
		}
		&--lg &__item:last-child {
			margin: 0;
			border: none;
		}
		&--lg &__link {
			padding: 0.75rem !important;
			border: 1px solid #dadfeb !important;
			border-radius: 50%;
		}
	}
	@media (config.$lg-up) {
		&__list {
			display: flex;
			gap: $spacer * 0.75;
		}
		&__link {
			justify-content: center;
			padding: 0.4375rem;
			border: 1px solid $border-color;
			border-radius: $btn-border-radius;
			background: $white;
		}
	}
}

[data-bs-theme=dark] {
	.m-main {
		&__link {
			color: #C0C6D6;
			background: #0C0F17;
		}
		// MODIF
		&--lg &__link {
			border-color: #323742;
		}
		@media (config.$lg-down) {
			&__item:last-child {
				border-top: 1px solid #323742;
			}
		}
		@media (config.$lg-up) {
			&__link {
				border: 1px solid #323742;
			}
		}
	}
}
