@use 'config';

.dropdown-cap {
	display: none;
	&__title {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
		border-bottom: 1px solid $border-color;
		font-size: 0.825rem;
		span:not(.icon-svg) {
			flex: 1 1 auto;
			margin-left: 0.5rem;
			padding-left: 0.5rem;
			border-left: 1px solid $border-color;
		}
	}
	&__back {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.25rem;
	}
	&__name {
		margin-bottom: 0.25rem;
		padding: 0 0.5rem;
		font-size: 0.75rem;
	}
	&__list {
		@extend %reset-ul;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 0.25rem;
		padding: 0 0.5rem 0.5rem;
	}
	&__item {
		@extend %reset-ul-li;
	}
	&__link {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.375rem 0.125rem;
		border: 1px solid $border-color;
		border-radius: 0.5rem;
		color: #505f79;
		transition: color $t, background $t, border-color $t;
	}
	// MODIF
	&--secondary {
		margin: -0.25rem -0.25rem 0;
	}
	&--secondary &__list {
		grid-template-columns: repeat(1, 1fr);
	}
	&--secondary &__link {
		border-color: transparent;
		justify-content: flex-start;
		padding: 0.4375rem 0.5rem;
	}
	// STATES
	.hoverevents &__link:hover {
		border-color: $primary;
		background: $primary;
		color: $white;
	}
  &.is-open {
    display: block;
  }
	&__link.is-active {
		background: #ecf1ff;
	}
	@media (config.$lg-down) {
		position: absolute;
		inset: 0;
		&__scroll {
			position: absolute;
			inset: 0;
		}
		&__title {
			padding: 1rem 0.75rem 0.5rem;
		}
		&__name {
			padding: 0.5rem 1rem;
		}
		&__list {
			padding: 0 0.75rem 0.5rem;
		}
		&__link {
			padding: 1rem 0.25rem;
		}
	}
	@media (config.$lg-up) {
		width: 14.25rem;
		padding: 0.25rem;
		&__scroll {
			max-height: 50dvh;
		}
		&__title {
			padding: 0.25rem 0.5rem;
		}
	}
}

[data-bs-theme=dark] {
	.dropdown-cap {
		&__title {
			border-color: #323742;
			span:not(.icon-svg) {
				border-color: #323742;
			}
		}
		&__link {
			color: #C0C6D6;
			border-color: #323742;
		}
		&__back {
			color: #C0C6D6;
		}
	}
	//&.hoverevents .dropdown-spec__link:hover {
	//	background: #223148;
	//	color: #E0E2EC;
	//}
}
