@use 'config';

// COLORS
$primary: #0074db;
$link-hover-color: #005eb4;
$danger: #ba1a1a;
$danger-light: #ffdad6;
$dark-color: #181c22;

$body-bg: #f9f9ff;
$body-color: #505f79;
$color-grey: #505f79;
$color-grey2: #e6e8f1;
$border-color: #dadfeb;
// DARKMODE
$body-color-dark: #c0c6d6;
$body-bg-dark: #181c26;
$border-color-dark: #323742;
$danger-dark: #93000a;
$danger-light-dark: #ffb4ab;
// BTN
$btn-font-size: 0.875rem;
$btn-line-height: 1rem;
$btn-font-weight: 500;
$btn-border-radius: 2.5rem;
$btn-padding-x: 0.5rem;
$btn-padding-y: 0.5rem;
// BTN SM
$btn-border-radius-sm: 2.5rem;
$btn-line-height-sm: 1rem;
$btn-padding-x-sm: 0.75rem;
$btn-padding-y-sm: 0.4375rem;
// BTN LG
$btn-border-radius-lg: 2.5rem;
$btn-line-height-lg: 1rem;
$btn-font-size-lg: 0.875rem;
$btn-padding-x-lg: 1.5rem;
$btn-padding-y-lg: 0.9375rem;
// FORMS
$input-padding-y: 0.5625rem;
$input-line-height: 1.25;
$input-placeholder-color: #dadfeb;
$input-bg: #ffffff;
$input-border-radius: 0.75rem;
$input-focus-border-color: #717785;
$input-focus-box-shadow: 0 0 0 0.25rem rgba(#adb5bd, 0.25);
// TOOLTIP
$tooltip-bg: #505f79;
$tooltip-opacity: 1;
// DROPDOWN
$dropdown-link-hover-bg: #ffffff;
$dropdown-link-active-bg: #ffffff;
$dropdown-box-shadow: 0 4px 9px 0 rgba(#000000, 0.1), 0 16px 16px 0 rgba(#000000, 0.09);
$dropdown-border-color: #dadfeb;
// MODAL
$modal-content-bg: #ffffff;
$modal-content-border-width: 0;
$modal-inner-padding: 1.5rem;
$modal-footer-margin-between: 3rem;
$modal-header-padding: 0.75rem 1.5rem;
$modal-header-border-width: 0;
$modal-content-border-radius: 1.5rem;
$modal-content-box-shadow-xs: 0 4px 9px 0 rgba(#000000, 0.1), 0 16px 16px 0 rgba(#000000, 0.09), 0 36px 22px 0 rgba(#000000, 0.05),
	0 16px 16px 0 rgba(#000000, 0.09);
$modal-sm: 490px;
$modal-md: 620px;
$modal-lg: 870px;
$modal-xl: 920px;
// NAV
$nav-link-color: #505f79;
$nav-tabs-link-active-color: #0074db;
$nav-tabs-link-active-border-color: #ffffff #ffffff #0074db;
$nav-tabs-link-hover-border-color: #ffffff #ffffff #dadfeb;
$nav-tabs-link-active-bg: #ffffff;
$nav-tabs-link-hover-color: #181c22;
$nav-link-padding-x: 0.875rem;
$nav-link-padding-y: 0.5625rem;
// Font
$font-family-base: 'Work Sans', -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, helvetica, arial, sans-serif;
$font-family-secondary: 'Merriweather';
$headings-font-family: 'Merriwearger';
$font-weight-base: 500;
$line-height-base: 1.3;
$h1-font-size: 1.375rem;
$h2-font-size: 1.375rem;
$h3-font-size: 1.125rem;

// Focus
$focus-outline-color: #0077cc;
$focus-outline-style: solid;
$focus-outline-width: 2px;

// Spacing
$utils-spacing: (
	'0': 0,
	'sm': 8px,
	'md': 12px,
	'lg': 24px,
	'xl': 48px
);

// Paths
$img-path: map-get(config.$paths, 'images');
$fonts-path: map-get(config.$paths, 'fonts');

// Transitions
$t: 0.3s;
