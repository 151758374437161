@use 'config';

.f-search {
	position: relative;
	&__wrap {
		position: relative;
	}
	&__inp {
		position: relative;
		margin-bottom: 0;
		.form-control {
			min-width: 18.75rem;
			padding: 0.375rem 3.5rem 0.375rem 0.75rem;
			font-size: 0.875rem;
			&::placeholder {
				color: $border-color;
			}
		}
	}
	&__btns {
		position: absolute;
		top: 50%;
		right: 0;
		z-index: 2;
		transform: translateY(-50%);
		.btn:first-child {
			padding-right: 0;
		}
		.btn:last-child {
			padding-left: 0;
		}
	}
	&__suggest {
		z-index: 10;
	}
	&__close {
		visibility: hidden;
		opacity: 0;
		transition: opacity $t, visibility $t;
		pointer-events: none;
	}
	&__scroll {
		max-height: 17.5rem;
	}
	// STATES
	&.is-searching &__suggest,
	&.is-searching &__close {
		visibility: visible;
		opacity: 1;
		pointer-events: all;
	}
	@media (config.$lg-down) {
		&__wrap {
			> :last-child {
				margin-bottom: 0;
			}
		}
		&__inp {
			margin-bottom: 0.75rem;
			padding: 0 0.5rem;
		}
		.form-control {
			height: 2.5rem;
			&:focus {
				border-color: $primary;
			}
		}
		&__suggest {
			padding: 0 0.5rem;
		}
	}
	@media (config.$lg-up) {
		&__suggest {
			position: absolute;
			top: calc(100% + 0.25rem);
			right: 0;
			left: 0;
			padding: 0.25rem;
			border: 1px solid #dadfeb;
			border-radius: 0.75rem;
			background: $white;
			visibility: hidden;
			opacity: 0;
			transition: opacity $t, visibility $t;
			box-shadow: 0 0.25rem 0.5625rem rgba($black, 0.1);
		}
	}
}

[data-bs-theme=dark] {
	.f-search {
		&__inp {
			.form-control {
				background: #0C0F17;
				&::placeholder {
					color: #323742;
				}
			}
		}
		@media (config.$lg-up) {
			&__suggest {
				background: #0C0F17;
				border-color: #323742;
			}
		}
	}
}
