.b-border {
	padding: 0.75rem;
	border: 1px solid #dadfeb;
	border-radius: 0.75rem;
}
[data-bs-theme=dark] {
	.b-border {
		border-color: #323742;
	}
}
