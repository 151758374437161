@use 'config';

.b-datagrid {
	display: none;
	flex: 1 1 auto;
	justify-content: center;
	padding: 0rem 0.5rem;
	border: 0.5rem solid #ebedf7;
	// STATES
	&.is-active {
		display: flex;
	}

	.dx-row{
		img {
			width: 100% !important;
			max-width: 100% !important;
			height: auto !important;
		}
	}
}
[data-bs-theme=dark] {
	.b-datagrid {
		border-color: #2D333C;
	}
}
