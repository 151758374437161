@use 'config';

.b-bookmarks {
	position: relative;
	padding: 0.25rem 0.75rem;
	&__wrap {
		overflow-x: auto;
	}
	&__list {
		display: flex;
		gap: 0.125rem;
		align-items: center;
	}
	&__num {
		padding: 0.125rem 0.375rem;
		border-radius: 50%;
		background: #ecf1ff;
	}
	&__list {
		@extend %reset-ul;
		z-index: 1010;
		position: relative;
	}
	&__item {
		@extend %reset-ul-li;
		position: relative;
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}
	&__link {
		white-space: nowrap;
		position: relative;
		display: block;
		padding: 0.5rem 2.125rem 0.5rem 0.625rem;
		border-radius: 0.5rem;
		color: $body-color;
		line-height: 1;
		transition: background $t, color $t;
		.icon-svg {
			color: $dark-color;
		}
	}
	&__new {
		position: relative;
		display: block;
		background: $white;
		color: $body-color;
		line-height: 1;
		transition: border-color $t;
	}
	&__new {
		margin-left: 1rem;
		padding: 0.25rem;
		border: 1px solid #dadfeb;
		border-radius: 0.5rem;
		&::before {
			content: '';
			position: absolute;
			top: 0.25rem;
			bottom: 0.25rem;
			left: -0.6rem;
			width: 0.0625rem;
			background: $border-color;
		}
	}
	&__close {
		position: absolute;
		right: 0;
		padding-right: 0.625rem;
		color: #505f79;
		transition: color $t;
	}
	// MODIF
	&__item--map {
		position: sticky;
		left: 0;
		z-index: 10;
	}
	&__item--map &__link {
		background: #f9f9ff;
	}
	// STATES
	.hoverevents &__item--new:hover &__new {
		border-color: #717785;
	}
	.hoverevents &__close:hover {
		color: $dark-color;
	}
	.hoverevents &__link:hover {
		color: $primary;
	}

	@media (config.$lg-down) {
		background: $white;
		font-size: 0.825rem;
		width: 100vw;
		> .dropdown-menu {
			position: absolute !important;
			top: calc(100% + 3px) !important;
			left: 5px !important;
			right: 5px !important;
			.dropdown-menu__scroll {
				position: relative !important;
				max-height: 30dvh;
			}
		}
		&__item {
			> * {
				flex: 1 1 auto;
			}
			.icon-svg--chevron {
				transition: transform $t;
			}
			.dropdown-menu__scroll {
				position: relative !important;
				max-height: 30dvh;
			}
		}
		&__link {
			border-radius: 40px;
		}
		&__item--map &__link{
			padding: 0.5rem 0.625rem;
		}
		&__item--map &__link.is-active {
			.icon-svg {
				color: $white;
			}
		}
		// STATES
		&__link.is-active {
			background: $primary;
			color: $white;
		}
		&__link.is-active + &__close {
			color: $white;
		}
		.btn.is-bookmarks-open {
			background-color: $primary !important;
			color: $white !important;
			.icon-svg--chevron {
				transform: rotate(90deg);
			}
		}
		.btn.is-bookmarks-open &__num {
			background: #3591ff;
		}
		&.is-bookmarks-open &__list {
			visibility: visible;
			opacity: 1;
			pointer-events: all;
		}
	}

	@media (config.$lg-up) {
		padding: 0.5rem 0 0;
		&__wrap {
			padding-right: 1rem;
		}
		&__item {
			justify-content: center;
		}
		&__link {
			padding: 0.75rem 2.125rem 0.75rem 0.625rem;
			border-radius: 0.5rem 0.5rem 0 0;
			&::before,
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				width: 1rem;
				height: 1rem;
				visibility: hidden;
				opacity: 0;
				transition: visibility $t, opacity $t;
			}
			&::before {
				right: 100%;
				background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 16A16 16 0 0 0 16 0v16H0Z' fill='%23EBEDF7'/%3e%3c/svg%3e");
			}
			&::after {
				left: 100%;
				background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16 16A16 16 0 0 1 0 0v16h16Z' fill='%23EBEDF7'/%3e%3c/svg%3e");
			}
		}
		// MODIF
		&__item--new {
			padding-right: 1rem;
		}
		&__item--map &__link {
			padding: 0.75rem 0.625rem;
		}
		// STATES
		&__link.is-active {
			background: #ebedf7;
		}
		&__link.is-active::before,
		&__link.is-active::after {
			visibility: visible;
			opacity: 1;
		}
	}
}
[data-bs-theme=dark] {
	.b-bookmarks {
		&__link {
			color: #C0C6D6;
			.icon-svg {
				color: #E0E2EC;
			}
		}
		&__close {
			color: #C0C6D6;
		}
		&__new {
			color: #C0C6D6;
			background: #0C0F17;
			border-color: #323742;
			&::before {
				background: #323742;
			}
		}
		@media (config.$lg-down) {
			background: #0C0F17;
			&__list {
				background: #0C0F17;
			}
		}
		@media (config.$lg-up) {
			&__link {
				&::before {
					right: 100%;
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 16A16 16 0 0 0 16 0v16H0Z' fill='%232D333C'/%3e%3c/svg%3e");
				}

				&::after {
					left: 100%;
					background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M16 16A16 16 0 0 1 0 0v16h16Z' fill='%232D333C'/%3e%3c/svg%3e");
				}
			}
			&__link.is-active {
				background: #2D333C;
			}
		}
	}
}
