@use 'config';

.b-content {
	&__img {
		img {
			border-radius: 0.75rem;
			overflow: hidden;
		}
	}
	@media (config.$lg-up) {
		&__top {
			display: flex;
			gap: 1.5rem;
		}
		&__side {
			flex: 0 0 12.5rem;
		}
	}
}
