@use 'config';

.modal {
	z-index: 10001;
	&-title {
		font-family: $font-family-secondary;
	}
	&-body {
		> :last-child {
			margin-bottom: 0 !important;
		}
	}
	&-footer-inner {
		display: flex;
		gap: 1rem;
		flex: 1 1 auto;
		justify-content: space-between;
		align-items: center;
	}
	// MODIF
	&-dialog-bottom {
		display: flex;
		align-items: flex-end;
		min-height: 100%;
		margin-bottom: 0;
		margin-inline: auto;
		.modal-header {
			padding-top: 2rem;
			&::before {
				content: '';
				position: absolute;
				top: 0.75rem;
				left: 50%;
				width: 8.375rem;
				height: 0.3125rem;
				margin-left: -4.1875rem;
				border-radius: 1rem;
				background: #dadfeb;
			}
		}
		.modal-content {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			.tab-content{
				img{
					max-width: 100%;
					height: auto !important;
				}
			}
		}
	}
	&-xs {
		--bs-modal-width: 350px;
	}
	@media (config.$lg-down) {
		&-body {
			padding-inline: 0.75rem;
		}
		&-footer {
			padding-top: 0.75rem;
			border-top: 1px solid $border-color;
		}
		&-footer-inner {
			margin: 0.75rem;
		}
	}
}

.modal-backdrop {
	z-index: 10000;
}

[data-bs-theme=dark] {
	.modal {
		&-content {
			background: #0C0F17;
		}
		&-header {
			&::before {
				background: #323742;
			}
		}
	}
}
