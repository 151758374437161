@use 'config';

.nav {
	&-link {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			right: -0.0625rem;
			bottom: -0.0625rem;
			left: -0.0625rem;
			z-index: 1;
			height: 0.125rem;
			background: #0074db;
			opacity: 0;
			transition: opacity $t;
		}
	}
	&-tabs:not(.nav-tabs--secondary) {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			right: -1.5rem;
			bottom: -0.0625rem;
			left: -1.5rem;
			height: 0.0625rem;
			background: $border-color;
		}
	}
	// MODIF
	&-tabs--secondary {
		border-bottom: none;
		.nav-link {
			border-bottom: none;
		}
		.nav-link.active {
			border-radius: 0.75rem 0.75rem 0 0;
			background: #f1f3fd;
		}
	}
	// STATES
	&-link.active {
		&::before {
			opacity: 1;
		}
	}
	.hoverevents &-link:hover {
		border-bottom-color: $primary;
		color: $dark-color;
	}
	@media (config.$lg-down) {
		&-link {
			padding: 0.375rem;
		}
		&-tabs:not(.nav-tabs--secondary) {
			position: relative;
			&::before {
				right: -0.75rem;
				left: -0.75rem;
			}
		}
	}
}
.tab-pane {
	> :last-child {
		margin-bottom: 0;
	}
	&--secondary {
		padding: 1.5rem;
		border: 0.0625rem solid #dadfeb;
		border-radius: 0 0.75rem 0.75rem;
	}
}
[data-bs-theme=dark] {
	.nav-tabs:not(.nav-tabs--secondary)::before {
		background: #323742;
	}

	.nav-link {
		color: #C0C6D6;
	}
	.tab-pane--secondary {
		border: 0.0625rem solid #323742;
	}
	.nav-tabs .nav-link.active,
	.nav-tabs .nav-item.show .nav-link,
	.nav-tabs--secondary .nav-link.active {
		background: #181C22;
		border-color: #181C22;
	}
	&.hoverevents .nav-link:hover {
		background: transparent;
		border-color: transparent;
		color: #E0E2EC;
	}
}
