.icon-svg {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	width: 16px;
	&__svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		fill: currentColor;
		pointer-events: none;
		transform: translateZ(0);
	}
	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	
	&--360 {
		width: 20px;
	} 
	&--plus {
		width: 18px;
	} 
	

}