.submenu {
	display: none;
	margin-top: 0.25rem;
	&__list {
		@extend %reset-ul;
		position: relative;
		padding-left: 1.5rem;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 1rem;
			left: 1rem;
			width: 1px;
			background: #dadfeb;
		}
	}

	&__item {
		@extend %reset-ul-li;
		margin-bottom: 0.25rem;
	}

	&__link {
		position: relative;
		display: flex;
		gap: 0.5rem;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem;
		border-radius: 0.5rem;
		color: #505f79;
		transition: color $t, background $t;

		.icon-svg--chevron {
			transition: transform $t;
		}

		span {
			display: flex;
			gap: 0.5rem;
			align-items: center;
		}

		span:not(.icon-svg) {
			flex: 1 0 auto;
		}

		&::before {
			content: '';
			position: absolute;
			top: 1.1rem;
			left: -0.5rem;
			width: 0.5rem;
			height: 1px;
			background: #dadfeb;
		}
		.icon-svg {
			flex: 0 0 1rem;
		}
	}
	&--in &__item {
		margin: 0 0 0.25rem;
	}
	&--in &__link {
		padding-left: 2rem;
		display: block;
		span {
			display: block;
		}
		.icon-svg {
			position: absolute;
			left: 0.5rem;
			top: 0.625rem;
		}
	}

	// STATES
	&__link.is-open {
		background: #f5f5ff;
		color: $dark-color;
		.icon-svg--chevron {
			transform: rotate(-90deg);
		}
	}
	&__link.is-open + .submenu--in {
		display: block;
	}
	.hoverevents &__link:hover {
		background: #f5f5ff;
		color: $dark-color;
	}
	&--in & {
		.hoverevents &__link:hover,
		&__link.is-open {
			background: $primary;
			color: $white;
		}
	}
}
[data-bs-theme=dark] {
	.submenu {
		&__link.is-open,
		&__link:hover {
			color: #E0E2EC;
			background: #223148;
		}
	}
}
