@use 'base/variables';
@use 'config';

.container {
	display: grid;
	// grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 94.5rem) minmax(1.5rem, 1fr);
	grid-template-columns: minmax(0.75rem, 1fr) minmax(auto, 100%) minmax(0.75rem, 1fr);
	> * {
		grid-column: 2;
	}
	> .b-map {
		grid-column: 1/4;
	}
	@media (config.$xl-up) {
		grid-template-columns: minmax(1.5rem, 1fr) minmax(auto, 100%) minmax(1.5rem, 1fr);
	}
}
