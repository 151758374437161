.btn {
	.icon-svg {
		display: flex;
	}
	&-link {
		color: $color-grey;
		font-weight: $font-weight-medium;
		text-decoration: none;
	}
	&-icon {
		display: inline-flex;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;
	}
	&-outline-secondary {
		--bs-btn-active-color: $dark-color;
		--bs-btn-active-bg: #ecf1ff;
		border-color: #dadfeb;
		background: $white;
		color: #505f79;

		&:active,
		&:focus {
			background-color: var(--bs-btn-active-bg) !important;
			color: var(--bs-btn-active-color) !important;
		}
	}
	// STATES
	.hoverevents &-outline-secondary:hover {
		background-color: #ecf1ff;
		color: #505f79;
	}
}

[data-bs-theme=dark] {
	.btn {
		&-link {
			color: #C0C6D6;
		}
		&-outline-secondary {
			--bs-btn-active-color: #E0E2EC;
			--bs-btn-active-bg: #223148;
			background: #0C0F17;
			border-color: #323742;
			color: #C0C6D6;
			&:hover,
			&:active,
			&:focus {
				background-color: var(--bs-btn-active-bg) !important;
				color: var(--bs-btn-active-color) !important;
			}
		}
	}
}
