.btn-group {
	display: inline-flex;
	border: 1px solid #dadfeb;
	border-radius: $btn-border-radius;
	background: $white;
	transition: border-color $t;
	.btn {
		border: none;
	}
	// STATES
	.hoverevents &:hover {
		border-color: #717785;
	}
}

[data-bs-theme=dark] {
	.btn-group {
		background: #0C0F17;
		border-color: #323742;
	}
}
