.demo-container {
	flex: 1 1 auto !important;
	display: flex !important;
}
.dx {
	&-pager.dx-widget {
		padding: 13px 0 115px !important;
	}
	&-page-sizes {
		color: #505f79 !important;
		overflow: hidden !important;
		border-radius: 24px !important;
		border: 1px solid #DADFEB !important;
		&:hover {
			transition: border-color 0.3s !important;
			border-color: #505f79 !important;
		}
		.dx-show-invalid-badge.dx-selectbox {
			background: white !important;
			&:before,
			&:after {
				display: none !important;
			}
		}
	}
	&-page-index {
		color: #505f79 !important;
		overflow: hidden !important;
		border-radius: 24px !important;
		border: 1px solid #DADFEB !important;
		background: white !important;
		min-width: 45px !important;
		&:hover {
			transition: border-color 0.3s !important;
			border-color: #505f79 !important;
		}
		&:before,
		&:after {
			display: none !important;
		}
	}
	&-button-normal {
		.dx-dropdowneditor-icon {
			&::before {
				content: '' !important;
				background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m6 4 4 4-4 4' stroke='%23505F79' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 16px;
				width: 20px !important;
				height: 20px !important;
				margin-top: -10px !important;
				margin-left: -11px !important;
				transform: rotate(90deg) !important;
				transition: transform 0.3s !important;
			}
		}
	}
	.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon::before,
	.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon::before {
		transform: rotate(-90deg) !important;
	}
	@media (min-width: 1060px) {
		&-pager.dx-widget {
			padding: 13px 0 !important;
		}
	}
}