@use 'config';

.dropdown {
	&-menu {
		margin-top: 0.125rem !important;
		padding: 0.25rem 0.25rem 0;
		border-radius: 0.75rem;
		border-color: $dropdown-border-color;
		background: $white;
		font-size: 0.875rem;
		box-shadow: $dropdown-box-shadow;
	}
	&-item {
		white-space: wrap !important;
		margin-bottom: 0.25rem;
		padding: 0;
		font-weight: $font-weight-medium;
	}
	&-link {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem;
		border-radius: 0.5rem;
		color: #505f79;
		transition: color $t, background $t;
		.icon-svg--chevron {
			transition: transform $t;
		}
		span {
			display: flex;
			gap: 0.5rem;
			align-items: center;
		}
	}
	// MODIF
	&--secondary &__item {
		margin: 0;
	}
	// STATES
	&-link.is-open {
		background: #f5f5ff;
		color: $dark-color;
		.icon-svg--chevron {
			transform: rotate(-90deg);
		}
	}
	&-link.is-open + .submenu {
		display: block;
	}
	&-link.is-active {
		background: $primary;
		color: $white;
	}
	.hoverevents &-item:hover {
		background: none;
	}
	.hoverevents &-link:hover {
		background: #f5f5ff;
		color: $dark-color;
	}
	&--secondary & {
		&-link:hover {
			background: $primary;
			color: $white;
		}
	}
	@media (config.$lg-up) {
		&-menu--width {
			width: 19.375rem;
		}
	}
}
[data-bs-theme=dark] {
	.dropdown {
		&-menu {
			background: #0C0F17;
			border-color: #323742;
		}
		&-link {
			color: #C0C6D6;
			&.is-active {
				color: $white;
			}
			&.is-open {
				background: #223148 !important;
			}
		}
	}
	&.hoverevents .dropdown-link:hover {
		background: #223148;
		color: #E0E2EC;
	}
	&.hoverevents .dropdown-link.is-active:hover {
		color: $white;
		background: $primary;
	}
}
