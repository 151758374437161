@use 'base/variables';

@font-face {
	font-family: 'Merriweather';
	font-weight: normal;
	font-style: normal;
	src: url(variables.$fonts-path + 'merriweather-400.woff2') format('woff2');
	font-display: fallback;
}

@font-face {
	font-family: 'Work Sans';
	font-weight: normal;
	font-style: normal;
	src: url(variables.$fonts-path + 'work-sans-400.woff2') format('woff2');
	font-display: fallback;
}

@font-face {
	font-family: 'Work Sans';
	font-weight: 500;
	font-style: normal;
	src: url(variables.$fonts-path + 'work-sans-500.woff2') format('woff2');
	font-display: fallback;
}

@font-face {
	font-family: 'Work Sans';
	font-weight: 700;
	font-style: normal;
	src: url(variables.$fonts-path + 'work-sans-700.woff2') format('woff2');
	font-display: fallback;
}
