.list {
	flex: 1 1 auto;
	&__list {
		counter-reset: my-counter;
		padding: 0;
		list-style: none;
	}
	&__item {
		border: 1px solid transparent;
		counter-increment: my-counter;
		position: relative;
		margin-bottom: 0.25rem;
		padding: 0.25rem 0.75rem 0.25rem 3rem;
		border-radius: 0.75rem;
		transition: background $t;
		cursor: pointer;
	}
	&__num {
		content: counter(my-counter);
		position: absolute;
		top: 0.15rem;
		left: 0.75rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 50%;
		background-color: $color-grey2;
		color: $color-grey;
		font-weight: $font-weight-bold;
		font-size: 0.875rem;
		transition: color $t, background $t;
	}
	&__link {
		padding: 0.1rem 0.4rem;
		border-radius: 0.2rem;
		background: $danger-light;
		color: $danger;
		text-decoration: none;
		transition: background $t;
	}
	&__text {
		.strongNum {
			color: $danger;
			display: inline-block;
			transform: translateY(-0.2em) scale(0.8);
			padding: 0;
			border-radius: 0.2rem;
			text-decoration: none;
		}
	}
	// STATES
	&__item.is-active {
		border: 1px solid $primary;
		background: #ecf1ff;
		color: $dark-color;
	}
	&__item.is-active &__num {
		background: $primary;
		color: $white;
	}
	// STATES
	&__item.is-hover {
		background: #ecf1ff;
		color: $dark-color;
	}
	&__item.is-hover &__num {
		background: $primary;
		color: $white;
	}
	.hoverevents &__item:not(.is-active):hover {
		background: #ecf1ff;
	}
	.hoverevents &__item:not(.is-active):hover &__num {
		background: #ecf1ff;
		color: $dark-color;
	}
	.hoverevents &__link:hover {
		background: #f7dedb;
	}
}

[data-bs-theme=dark] {
	.list {
		&__item &__num {
			background-color: #323742;
			color: #C0C6D6;
		}
		&__link {
			background: $danger-dark;
			color: $danger-light-dark;
		}
		// STATES
		&__item.is-active {
			border: 1px solid $primary;
			background: #223148;
			color: #E0E2EC;
		}
		&__item.is-hover {
			background: #223148;
		}
		&__item.is-hover &__num {
			background: #223148;
			color: #E0E2EC;
		}
	}
	&.hoverevents .list__item:not(.is-active):hover {
		background: #223148;
	}
	&.hoverevents .list__item:not(.is-active):hover .list__num {
		background: #223148;
		color: #E0E2EC;
	}
	&.hoverevents .list__link:hover {
		background: #223148;
	}
}
