@use 'config';

.b-suggest {
	font-size: 0.875rem;
	&__list {
		@extend %reset-ul;
	}
	&__item {
		@extend %reset-ul-li;
		margin-bottom: 0.25rem;
	}
	&__link {
		display: block;
		padding: 0.25rem 0.5rem;
		border-radius: 0.5rem;
		color: $body-color;
		text-decoration: none;
		transition: background $t, color $t;
		span {
			display: block;
		}
	}
	// STATES
	.hoverevents &__link:hover {
		background: #ecf1ff;
		color: $dark-color;
	}
}
[data-bs-theme=dark] {
	.b-suggest {
		&__link {
			color: #C0C6D6;
		}
	}
	&.hoverevents .b-suggest__link:hover {
		background: #223148;
		color: #E0E2EC;
	}
}