.avatar {
	display: flex;
	gap: $spacer * 0.5;
	align-items: center;
	color: $body-color;
	&__img {
		flex: 0 0 32px;
		border: 1px solid $white;
		border-radius: 50%;
		font-size: 0;
		overflow: hidden;
	}
}
[data-bs-theme=dark] {
	.avatar {
		&__img {
			border-color: #0C0F17;
		}
	}
}
