@use 'config';

.dropdown-spec {
	&__wrap {
		padding: 0.25rem;
	}
	&__top {
		margin: 0;
		padding: 0.5rem;
		font-size: 0.75rem;
	}
	&__search {
		margin-bottom: 0.5rem;
		.form-control {
			min-width: 0 !important;
			height: 2.5rem;
			border-width: 1px 0;
			border-radius: 0;
		}
		.f-search__btns {
			padding-right: 1rem;
		}
	}
	&__title {
		margin-bottom: 0.25rem;
		padding: 0 0.75rem;
		color: $primary;
	}
	&__list {
		@extend %reset-ul;
		padding: 0 0.25rem;
	}
	&__list + &__title {
		margin-top: 0.5rem;
	}
	&__item {
		@extend %reset-ul-li;
		margin-bottom: 0.25rem;
		padding: 0;
		font-weight: $font-weight-medium;
	}
	&__link {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem;
		border-radius: 0.5rem;
		color: #505f79;
		transition: color $t, background $t;
	}
	.form-switch {
		min-height: 0;
		padding-left: 1.8rem;
	}
	// MODIF
	&--secondary &__list {
		padding: 0;
	}
	// STATES
	&.is-close {
		display: none;
	}
	&__link.is-active {
		color: $white;
		background: $primary;
	}
	.hoverevents &__item:hover {
		background: none;
	}
	.hoverevents &__link:hover {
		background: #f5f5ff;
		color: $dark-color;
	}
	@media (config.$lg-down) {
		&__top {
			padding: 1rem 0.75rem 0.5rem;
		}
		&__search {
			margin-right: -0.5rem;
			margin-left: -0.5rem;
			.form-control {
				height: 3.5rem;
			}
		}
		// MODIF
		&--scroll,
		&--scroll &__wrap,
		&--scroll &__scroll {
			position: absolute;
			inset: 0;
		}
	}
	@media (config.$lg-up) {
		width: 14.25rem;
		&__scroll {
			max-height: 50dvh;
		}
	}
}
[data-bs-theme=dark] {
	.dropdown-spec {
		&__menu {
			background: #0C0F17;
			border-color: #323742;
		}
		&__link {
			color: #C0C6D6;
			&.is-active {
				color: $white;
			}
		}
	}
	&.hoverevents .dropdown-spec__link:hover {
		background: #223148;
		color: #E0E2EC;
	}
	&.hoverevents .dropdown-spec__link.is-active:hover {
		color: $white;
		background: $primary;
	}
}
