@use 'config';

.m-side {
	&__list {
		display: flex;
		align-items: center;
		gap: 0.75rem;
	}
	@media (config.$lg-down) {
		&__list {
			gap: 0.25rem;
			flex-wrap: wrap;
		}
		&__item {
			flex: 0 0 auto;
			width: calc(50% - 0.25rem);
			.btn {
				width: 100%;
				padding: 0.9375rem 1rem;
			}
		}
	}
	@media (config.$md-down) {
		&__item {
			width: 100%;
			.btn {
				text-align: left;
			}
		}
	}
}
