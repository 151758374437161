.tooltip {
	font-weight: $font-weight-medium;
	filter: drop-shadow(0 10px 12px rgba(0, 0, 0, 0.3));
	z-index: 99999;

	&-inner {
		small {
			.text-muted{
				color: inherit !important;
			}
		}

	}
}

[data-bs-theme=dark] {
	.tooltip {
		&-inner {
			color: #0C0F17;
			background: #C0C6D6;
		}
	}
	.bs-tooltip-auto .tooltip-arrow {
		&::before {
			border-top-color: #C0C6D6;
		}
	}
}
