.block-loader {
	position: relative;
	transition: opacity 0.3s;

	&__loader {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background: rgba(0, 0, 0, 0.5);
		visibility: hidden;
		opacity: 0;
		transform: translate(-50%, -50%);
		transition: opacity 0.3s 0s, visibility 0.3s 0s;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 30px;
			height: 30px;
			margin: -15px 0 0 -15px;
			border: 4px solid #fff;
			border-radius: 50%;
			border-top-color: rgba(0, 0, 0, 0);
		}
	}
	&.is-loading {
		pointer-events: none;
	}
	&.is-loading &__loader {
		visibility: visible;
		opacity: 1;
		transition-delay: 0s, 0s;
		&::after {
			animation: animation-rotate 0.8s infinite linear;
		}
	}
}

@keyframes animation-rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
