.badge {
	padding: 0 0.0625rem;
	border-radius: 0.25rem;
	background: #ffdad6;
	color: $danger;
}

/* Define the animation for adding the class */
.badge {
	transition: all 0.5s ease;
}

/* Optionally, define the starting style for the animation */
.word {
	transition: all 0.5s ease;
}
