.b-list {
	&__list {
		@extend %reset-ul;
	}
	&__item {
		@extend %reset-ul-li;
		margin-bottom: 0.25rem;
		padding: 0.5rem 0.75rem;
		border: 1px solid $border-color;
		border-radius: 0.75rem;
		color: $dark-color;
		> :last-child {
			margin-bottom: 0;
		}
		p {
			font-size: 0.875rem;

			.strongNum {
				color: $danger;
				display: inline-block;
				transform: translateY(-0.2em) scale(0.8);
				padding: 0;
				border-radius: 0.2rem;
				text-decoration: none;
			}
		}
	}
	&__inner {
		display: flex;
		align-items: center;
	}
	&__head {
		flex: 0 0 auto;
		width: 6.25rem;
	}
	&__title {
		margin-bottom: 0.125rem;
		color: $dark-color;
		font-size: 1rem;
	}
}
[data-bs-theme=dark] {
	.b-list {
		&__item {
			color: #c0c6d6;
			border: 1px solid #323742;
		}
		&__title {
			color: #c0c6d6;
		}
	}
}
